import React from 'react';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';
import SectionContainer from "../components/common/SectionContainer";
import SectionColumn from "../components/common/SectionColumn";

const IndexPage = () => {
    return (
        <Layout pageName="home">
            <Helmet>
                <title>leadinsight</title>
            </Helmet>

            <SectionContainer className={`404-section bg-white border-bottom text-center py-5`}>
                <SectionColumn columnWidthMedium={12} classes={`py-5`}>
                    <div className="py-5">
                        <h2>404 - Nicht gefunden</h2>
                        <p>Diese Seite existiert nicht.</p>
                    </div>
                </SectionColumn>
            </SectionContainer>

            <SectionContainer className={`visits-section bg-primary text-white py-5`}>
                <SectionColumn columnWidthMedium={6} classes="mx-auto">
                    <div className="text-center">
                        <h2>Interesse, Ihre Besucher <br/> zu identifizieren?</h2>
                        <p>Aktuell bieten wir noch keine öffentliche Registrierung an. Sprechen Sie uns für ein persönliches Angebot an. Oder haben Sie noch Fragen? Wir helfen Ihnen gerne weiter.</p>
                        <p className="lead"><a className="text-white fw-bold" href="tel:+4927138478920">+49 (0) 271 / 38 47 89 2-0</a> / <a className="text-white fw-bold" href="mailto:info@hype-media.de">info@hype-media.de</a></p>
                    </div>
                </SectionColumn>
            </SectionContainer>
        </Layout>
    );
};

export default IndexPage;
